import React from 'react'
import { NavLink } from 'react-router-dom'
import Header2 from './Header2'

const Error = () => {
    return (
        <div className='bg-light text-center '>
            <div className='bg-dark pb-5 d-none d-sm-block d-block'>
                <Header2 />
            </div>
            <div className="container p-5 fontFamilyHeader ">
                <div className="row error-container">
                    <div className="col-md-12 text-center ">
                        <h1 className="fs-1 mb-2">404</h1>
                        <p className="fs-6 mb-4">Oops! The page you are looking for could not be found.</p>
                        <NavLink className="text-decoration-none btn btn-lg btn-back fs-2" style={{backgroundColor:"black",color:"white"}} to="/" >Go Back</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
import React from 'react'
import { NavLink } from 'react-router-dom'

const ElectricFuture = () => {
    return (
        <>

            {/* <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../Image/ElectricFutureBackgroundImg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center',borderBottom:"2px solid white" }}>
                    <div>
                        <Header />
                    </div>
                    <div className="container " style={{ height: "85vh" }}>
                        <div className="row  d-flex justify-content-center align-itmes-center  text-white " >
                            <div className=" mt-5">
                                <div className='fontFamilyHeader1 pt-lg-2 mt-lg-2'>
                                    <h4 className='text-white ' style={{ fontSize: "77px" }}>Electric Future</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                
            </section> */}

            <section>
                <div className='container-fluid p-0 d-none d-sm-block' >
                    <div className='position-absolute text-white h1FontFamily1' style={{ top: "24%", left: "6%" }} >
                        <h2 style={{ fontSize: "5.059375vw", fontWeight: "600" }}>Electric Future</h2>
                    </div>
                    <video autoPlay loop muted className='w-100' style={{ objectFit: "fill", height: '700px' }}  >
                        <source src={require("../Image/Header4Video.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="container-fluid p-0 d-sm-none d-block">
                    <div className='position-absolute text-white h1FontFamily1' style={{ top: "43%", left: "8%" }} >
                        <h2 style={{ fontSize: "36px", fontWeight: "600" }}>Electric Future</h2>
                    </div>
                    <video autoPlay loop muted className='w-100' style={{ objectFit: "fill", height: '100%' }}  >
                        <source src={require("../Image/Header4ResponsiveVideo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row ms-lg-5">
                            <div className='fontFamilyHeader1 p-4' style={{ fontSize: "33px", }}>
                                <p>The engine of the future will be powered by electricity. And the future starts here and now. We invite you to take a glimpse at the innovations that will define our mobility. In doing so, we span the spectrum from today’s technologies to hydrogen and upcoming technologies. This electric future offers tremendous opportunities for a greener and more sustainable society.</p>
                            </div>
                            <div>
                                <p className='ms-lg-3' style={{ color: "#8E8EA7", fontSize: "18px" }}>18 April 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF1.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}>BMW   <br></br>BMW Films: THE CALM  </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF2.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b> THE ICON  </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  BMW as a driving force for sustainable mobility  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoEF1.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>  BMW i7  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> A new perspective  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF3.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  Digital dialog     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Arnold Schwarzenegger and the BMW i Vision Dee   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF4.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  BMW x Nikita Gale    <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>   “The automobile is always there, it feels almost invisible”    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF5.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Discover Japan    <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Virtual model Imma visits Kenichi Sasakawa   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF6.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Driven by your cheers   </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The dawn of an electric era  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF7.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b>  Goodbye, BMW i3  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>This love never gets rusty    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF8.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Circular power   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Touring into the future with Coldplay   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF9.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  Norway road trip      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Enjoy nature consciously – sustainable travel in the BMW iX    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF10.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Electrifying sound     <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>   How BMW composes the score for electric driving pleasure    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF11.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Electrifying passengers     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   Supercar Blondie takes an electric ride with Hans Zimmer   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderRight: "1px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/EFvideo1.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px" }}> Electric sportiness    <br></br> Electric Power on the Salzburgring: Testing the BMW i4 M50  </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/EFvideo2.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>  City Trip Oslo   </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Electrifying Sensations  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF12.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "6%", left: "5%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b> Marvel Studios x BMW </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  Two Munich Characters in Hollywood  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF13.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  Trends      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Model Cajsa Wessberg: “Design should reflect identity”   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/EFvideo3.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  Sustainability      <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  Roderick Pijls: kitesurfing at one with the elements    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF14.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Transalp     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   Two cyclists and the BMW iX go for a test spin   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/EFvideo5.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b> BMW i Design    </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>A family with character  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EF15.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b>  Automotive sensors  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>  The sense organs of driver assistance systems  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffectVIDEO h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/EFvideo4.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Electromobility    <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  A ten-pack of know-how: the best everyday e-car hacks   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF16.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>  WHAT TOOK YOU SO LONG?      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   WHAT’S YOUR REASON NOT TO CHANGE?   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF18.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Chasing iNEXT      <br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>   Episode 1: Where is the visionary supercar?     </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/EF19.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Electric Facts      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   Join us for a tour of electric facts from around the world   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center bg-white pt-5 pb-5 '>
                            <NavLink to=''><button className='p-2  SHOWMOREBUTTON' style={{ border: "2px solid black", }}><b className='p-lg-5  h1FontFamily1' style={{ fontSize: "17px" }}>Show more</b></button></NavLink>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default ElectricFuture
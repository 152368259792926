import React from 'react'
import "./AfterlifeBmwCar.css"
import { NavLink } from 'react-router-dom'

const AfterlifeBmwCar = () => {
    return (
        <>

            <section>
                <div className='container-fluid p-0 d-none d-sm-block' >
                    <div className='position-absolute text-white h1FontFamily1' style={{ top: "21%", left: "4%" }} >
                        <h2 style={{ fontSize: "4.59375vw", fontWeight: "600", lineHeight: "70px" }}>Circle of life: The <br /> afterlife of a BMW <br /> vehicle</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "cover", height: '700px' }}  >
                        <source src={require("../Image/AfterlifeBmwCarBG.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.

                    </video>
                </div >

                <div className='container-fluid p-0 d-sm-none d-block' >
                    <div className='position-absolute text-white h1FontFamily1 ' style={{ top: "49%", left: "7%" }} >
                        <h2 style={{ fontSize: "36px", fontWeight: "600" }}>Circle of life: The afterlife of a BMW vehicle</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '100vh' }}  >
                        <source src={require("../Image/AfterlifeBmwCarBGresponsivevideo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div >
            </section>

            <section>
                <div className="container-lg Main-Containerr ">
                    <div className="row ms-lg-5 me-lg-5 fontFamilyHeader1">
                        <div className=' common-font-size'>
                            <p> By setting a clear focus on circularity, BMW minimizes the environmental impact of a vehicle over its entire life cycle. One critical stage in this lifecycle is the recycling process, where what used to be the end of a car is now the beginning of something new. What does this journey look like? Experience it through the eyes of a BMW iX1 pre-series parked at the BMW Group Recycling and Dismantling Center in Unterschleißheim, Germany. </p>
                        </div>
                        <div>
                            <p className='ms-lg-3 ' style={{ color: "#8E8EA7", fontSize: "18px", fontWeight: "600" }}>21 April 2023</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr ">
                    <div className="row ms-lg-5  fontFamilyHeader1">
                        <div className="col-12">
                            <div className="row">
                                <div>
                                    <h5 className='fw-bold ms-lg-2 fs-6'> How does this sound ? </h5>
                                </div>

                                <div className='d-flex align-items-center ms-lg-3 mt-1' style={{ columnGap: "15px" }}>
                                    <div className='VolumeIcon'>
                                        <i class="fa-solid fa-volume-high " style={{ fontSize: "18px", color: "black" }} ></i>
                                    </div>
                                    <audio controls className='AudiaFirst'>
                                        <source src={require("../Image/first.mp3")} type="audio/ogg" />
                                        Your browser does not support the audio tag.
                                    </audio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row g-5 d-flex justify-content-center align-items-center fontFamilyHeader1 ms-lg-5">
                        <div className="col-lg-3">
                            <div>
                                <i class="fa-solid fa-bell" style={{ fontSize: "150px" }} ></i>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div>
                                <div className='d-flex mb-4 pb-2' >
                                    <div className='' style={{ borderTop: "4px solid black", width: "14%" }}>
                                    </div>
                                </div>
                                <h2 className='mb-3' style={{ fontSize: "26px", fontWeight: "400" }} >Always stay up to date</h2>
                                <p className='me-lg-4 pt-2' style={{ fontSize: "17px", fontWeight: "400" }} >
                                    Innovative mobility, exciting trends for the future and high RPMs: Subscribe now to get notified of new content.
                                </p>
                                <div className=''>
                                    <NavLink to=''><button className='p-2 mt-2 ActivateButton'  ><b className=' h1FontFamily1 '>Activate</b></button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr fontFamilyHeader1">
                    <div className="row p-lg-5 ms-lg-2">
                        <div className="col-lg-6">
                            <div>
                                <p className='me-lg-4' style={{ fontSize: "17.2px" }} >
                                    For some, going to the recycling center means you’ve grown old and are coming to your end. Not for me: I am a BMW iX1 pre-series. For those who don’t know what that is, think of it this way: I’m a sort of pioneer. I was a test vehicle produced to identify potential problems before the model goes on sale to the public. Having achieved that goal, I find myself here, practically new and very excited to take the next step. Actually, I have been here before. Well, at least parts of me have. Even now, BMW vehicles are made from up to thirty percent recycled and reused materials. That explains why I feel so at home here.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-center  ">
                        <div className='text-center fontFamilyHeader1  ReuseSize'>
                            <h4 className='FREUDE-STAYS-FOREVER'  > RE:USE & RE:CYCLE </h4>
                            <h6 className='CommonHsix' > Reincarnation of a circular vehicle </h6>
                        </div>

                        <div className="col-lg-5">
                        </div>

                        <div className="col-lg-5 ">
                            <div className='mt-lg-5 pt-3 me-lg-4 fontFamilyHeader1'>
                                <p className='WeAreAble' >
                                    "We are able to identify parts of the vehicle which are maybe not as good as they could be. And we communicate this within the company in order to improve the product at the end of the day."
                                </p>
                                <p className='CommonClient' >
                                    Alexander Schüll <br />
                                    Head of the BMW Group Recycling and Dismantling Center in Unterschleißheim
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr fontFamilyHeader1">
                    <div className="row p-lg-5 ms-lg-2">
                        <div className="col-lg-6">
                            <div>
                                <p className='me-lg-4' style={{ fontSize: "16.5px" }} >
                                    The recycling process is all about closing material cycles, preserving resources, and reusing them more efficiently. Everyone at this recycling and dismantling center is working on new solutions to maximize the recycling rate in the future including Alexander Schüll, Head of the BMW Group Recycling and Dismantling Center in Unterschleißheim. With this goal in mind, here is where the recycling process begins for me and for all other BMW vehicles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className='text-center fontFamilyHeader1  mb-5 '>
                        <h4 className='WelcomeBMW'> WELCOME TO THE BMW GROUP RECYCLING AND DISMANTLING CENTER </h4>
                    </div>
                    <div className="row d-flex justify-content-around align-items-center p-lg-4">
                        <div className="col-lg-6">
                            <div>
                                <img className='w-100' src={require("../Image/AfterlifeBmwCar1.png")} alt="Reviews1" />
                            </div>
                        </div>

                        <div className="col-lg-4 ">
                            <div className=' pt-3 me-lg-2 fontFamilyHeader1'>
                                <p className='' style={{ fontSize: "16px", lineHeight: "26px", fontWeight: "400", }} >
                                    The first step for us is being welcomed at this center. This is where the certificate of destruction is issued, which is required for the final decommissioning of the vehicle at the registration office. For me and other BMW vehicles ready to move into the next stage of our lives, this is where our new chapter officially begins.
                                </p>
                                <p className='' style={{ fontSize: "16px", lineHeight: "26px", fontWeight: "400", }} >
                                    Checking in, I noticed that there is a movie star among us. How exciting! It is a BMW vehicle that was specially modified for the film set. Here’s a fun fact: the BMW Group has been producing vehicles for blockbuster movies like the Mission: Impossible franchise since 2011. After filming wrapped, this BMW movie star was brought back here to be at least 95 percent recycled. What an action-packed life that must have been! Who knows, maybe I’ll be a movie star in my next life
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className='text-center h1FontFamily PRETREATMENTBOTTOM'>
                        <h4 className='FREUDE-STAYS-FOREVER'> PRETREATMENT </h4>
                        <h6 className='CommonHsix'  > Getting ready </h6>
                    </div>
                    <div className="row d-flex justify-content-around align-items-center p-lg-4">
                        <div className="col-lg-6">
                            <div>
                                <img className='w-100' src={require("../Image/AfterlifeBmwCar1.png")} alt="Reviews1" />
                            </div>
                        </div>

                        <div className="col-lg-4 ">
                            <div className=' pt-3 me-lg-3 fontFamilyHeader1'>
                                <p className='WeAreAble'  >
                                    " We remove the high-voltage battery first, which is then prepared for recycling. The rest of the vehicle is then further processed in the standardised recycling process. "
                                </p>
                                <p className='CommonClient' >
                                    Alexander Schüll <br />
                                    Head of the BMW Group Recycling and Dismantling Center in Unterschleißheim
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr fontFamilyHeader1">
                    <div className="row p-lg-5 ms-lg-2">
                        <div className="col-lg-6">
                            <div>
                                <p className='me-lg-4' style={{ fontSize: "16.5px" }} >
                                    What exists within a vehicle’s life cycle should remain inside for as long as possible. Here we get prepared for a safe process, battery first. At this workstation, for example, the refrigerant of the air conditioning system and the brake fluid are extracted; cooling water, engine and transmission oils are drained off; and the fuel is pumped out using a special extraction unit with an integrated drill. Once this is done, we all feel a lot lighter and relaxed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-around align-items-center ">
                        <div className='text-center h1FontFamily DISMANTLINGBOTTOM'>
                            <h4 className='FREUDE-STAYS-FOREVER '> DISMANTLING </h4>
                            <h6 className='CommonHsix' > Up or down, that’s the question </h6>
                        </div>

                        <div className="col-lg-6">
                        </div>

                        <div className="col-lg-6 ">
                            <div className=' mt-5 me-lg-4 fontFamilyHeader1'>
                                <p className='' style={{ fontSize: "16.5px" }} >
                                    During dismantling, parts are removed for further use and for material recycling. This includes downcycling and upcycling. Valuable materials such as precious metals from the catalyst are recycled in a separate material loop. Upcycling is the reuse of discarded materials for high-value applications, whereas downcycling means recycling discarded materials for low-value applications. Honestly, I’m just happy if most of my parts stay inside the circle and find a new purpose in life.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-around align-items-center ">
                        <div className='text-center h1FontFamily mb-lg-3 pb-3'>
                            <h4 className='RECYCLING'> RECYCLING THE VEHICLE’S REMAINS </h4>
                            <h6 className='CommonHsix2' > They lived up to 95 percent happily ever after </h6>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0">
                    <img className='w-100' style={{ height: "auto" }} src={require("../Image/AfterlifeBmwCar2.png")} alt="Reviews1" />
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-around align-items-center p-lg-4">
                        <div className="col-lg-6">
                        </div>

                        <div className="col-lg-6 ">
                            <div className=' pt-3 me-lg-5 fontFamilyHeader1'>
                                <p className='WeAreAble2 me-lg-3'  >
                                    " Each and every  vehicle which BMW plans to bring on the road in the coming years needs to be at least 95 percent recyclable. "
                                </p>
                                <p className='CommonClient' >
                                    Alexander Schüll <br />
                                    Head of the BMW Group Recycling and Dismantling Center in Unterschleißheim
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr fontFamilyHeader1">
                    <div className="row p-lg-4 ms-lg-2">
                        <div className="col-lg-6">
                            <div className='ms-lg-4'>
                                <p className='me-lg-5' style={{ fontSize: "16.5px" }} >
                                    So this is the part that might be the first thing some of you think of when you hear the word recycling: smashing old cars. When you put it that way, even that action movie star might get a little scared. But not me. Here, the remaining body of a BMW vehicle gets pressed down in a compactor. Obviously, the thought of being smashed to pieces could be frightening. But considering that my next life begins after this station, I find it very comforting. At the end of this process, all the remaining fragments in the car can be separated so they can each start a new life. Plastics, textiles, iron, and non-ferrous metals are recycled. Maybe they will become a refrigerator, a can or even a new BMW. Life is full of surprises, and that’s the beauty of it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-around align-items-center ">
                        <div className='text-center h1FontFamily mb-5'>
                            <h4 className='RECYCLING'> SEE YOU IN THE NEXT LIFE </h4>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid IMGHe" style={{ backgroundImage: `url(${require("../Image/AfterlifeBmwCar3.png")})`, backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} >
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr">
                    <div className="row d-flex justify-content-around align-items-center p-lg-3">
                        <div className="col-lg-6">
                        </div>

                        <div className="col-lg-6 ">
                            <div className=' pt-3 me-lg-5 fontFamilyHeader1'>
                                <p className='me-lg-4' style={{ fontSize: "16.2px", lineHeight: "26px", color: "#262626" }} >
                                    This is not the end. Not even close. It’s only the beginning of a new life cycle. As I said, I've been here before. And I’m pretty sure I’ll be back again and again. My life cycle has been carefully engineered and improved to preserve resources and protect the environment. Every step of the way from the very first design idea to this stop at the recycling center (➜ Read also: Sustainable materials). This is how circularity works (➜ Read also: Focus on circular economy) It is an ecosystem for life that allows us to live in harmony with our planet. Whether it’s on a film set in the form of a new BMW or as a refrigerator in your kitchen, no matter what my next journey will be, I can feel good knowing that people are working hard to make it as sustainable as possible.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg Main-Containerr ">
                    <div className="row ms-lg-5  fontFamilyHeader1">
                        <div className='Author d-none d-sm-block'  >
                            <p> Author: Narges Derakhshan; Art: Verena Aichinger; Lucas Lemuth; <br />
                                Illustrations: Annu Kilpeläinen </p>
                        </div>

                        <div className='Author d-sm-none d-block'  >
                            <p> Author: Narges Derakhshan; Art: Verena Aichinger; Lucas Lemuth;
                                Illustrations: Annu Kilpeläinen </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0">
                    <div className='Thei4car d-flex justify-content-center position-relative '>
                        <img className='' src={require("../Image/1035.png")} alt="" />
                        <div className='position-absolute ' style={{ top: "12%", right: "10%" }}>
                            <h2 className=' text-dark fontFamilyHeader' style={{ fontSize: "3.125vw", fontWeight: "400" }}>  THE iX1 </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block">
                    <div className="row fontFamilyHeader Main-Container-Fluid ">
                        <div className='text-center mb-4 pb-3'>
                            <h2 className='' style={{ fontSize: "40px" }} >More articles</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 p-0" style={{ borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/AfterlifeBmwCarBottom1.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> dream team   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Sustainability and its best players   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/AfterlifeBmwCarBottom2.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> Sustainability   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  BMW takes environmental responsibility seriously   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/AfterlifeBmwCarBottom3.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "7%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> Imagine …   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Driving tomorrow today    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center pt-5">
                            <div className="col-lg-12">
                                <div className='row g-4 d-flex justify-content-lg-center text-center p-3 '>
                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/'>
                                                <button className=''>
                                                    <span className='Hash'> # </span>  Electromobility
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/electricfuture'>
                                                <button>
                                                    <span className='Hash'> # </span>   Innovation
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/freude'>
                                                <button>
                                                    <span className='Hash'> # </span>   Insight
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/circularworld'>
                                                <button>
                                                    <span className='Hash'> # </span>  Reportage
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/digitaljourney'>
                                                <button>
                                                    <span className='Hash'> # </span>  Sustainability
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block">
                    <div className="row fontFamilyHeader Main-Container-Fluid ">
                        <div className='text-center mb-4 pb-3'>
                            <h2 className='' style={{ fontSize: "40px" }} >More articles</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 p-0" style={{ borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/MoreArticlsResponsive1.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> dream team   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Sustainability and its best players   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/MoreArticlsResponsive2.png")} alt="" />

                                <div className='position-absolute text-dark' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> Sustainability   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  BMW takes environmental responsibility seriously   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/MoreArticlsResponsive3.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "7%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> Imagine …   <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Driving tomorrow today    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center pt-5">
                            <div className="col-lg-12">
                                <div className='row g-4 d-flex justify-content-lg-center text-center p-3 '>
                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/'>
                                                <button className=''>
                                                    <span className='Hash'> # </span>  Electromobility
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/electricfuture'>
                                                <button>
                                                    <span className='Hash'> # </span>   Innovation
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/freude'>
                                                <button>
                                                    <span className='Hash'> # </span>   Insight
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/circularworld'>
                                                <button>
                                                    <span className='Hash'> # </span>  Reportage
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-6 ">
                                        <div className='AfterlifeBmwCarBottomButton'>
                                            <NavLink className='text-decoration-none' to='/digitaljourney'>
                                                <button>
                                                    <span className='Hash'> # </span>  Sustainability
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AfterlifeBmwCar
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routing from './components/Routing'
//import ScrollToTop from './components/ScrollToTop'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routing />
        {/* <ScrollToTop /> */}
      </BrowserRouter>
    </>
  )
}

export default App
import React from 'react'
import "./FreudeStays.css"
import { NavLink } from 'react-router-dom'

const FreudeStays = () => {
    return (
        <>

            <section>
                <div className='container-fluid' style={{ backgroundImage: `url(${require("../Image/HomeUpdateBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderBottom: "2px solid white" }}>
                    <div className="container circular-world-home-bg ">
                        <div className="row d-flex justify-content-center align-itmes-center  text-white " >
                            <div className="">
                                <div className='h1FontFamily1  position-absolute' style={{ top: "62%", left: "6%" }} >
                                    <h4 className='reamaning-heading-common-size text-white' style={{ fontSize: "4.8vw", fontWeight: "600" }} >Freude Stays Forever</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row ms-lg-5 me-lg-5 fontFamilyHeader1">
                            <div className=' p-lg-4 mt-2 common-font-size'>
                                <p> For over 100 years, we have been building cars that are known for their quality. But up to today, the crucial part of a BMW is not the motor, the chassis or the body: it’s the Freude of driving one. </p>
                            </div>
                            <div>
                                <p className='ms-lg-3 ' style={{ color: "#8E8EA7", fontSize: "18px" }}>14 December 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row d-flex justify-content-center  ">
                            <div className='text-center fontFamilyHeader1 mt-3 mb-3'>
                                <h4 className='FREUDE-STAYS-FOREVER'  > FREUDE STAYS FOREVER </h4>
                            </div>

                            <div className="col-lg-5">
                            </div>

                            <div className="col-lg-5 ">
                                <div className='mt-lg-5 pt-3 me-lg-4 fontFamilyHeader1'>
                                    <p className='' style={{ fontSize: "16px", lineHeight: "27px", color: "#262626", fontWeight: "400" }} >
                                        Life has many unforgettable moments in store for us. Some, we think back on fondly; others, less so. But every one of them is unique. That is why a BMW is more than just a method of transportation. It is a companion that creates space for emotion and allows us to feel the joy which lives within every single moment.
                                    </p>
                                    <p className='' style={{ fontSize: "16px", lineHeight: "27px", color: "#262626", fontWeight: "400" }} >
                                        Dive into the emotional farewell drive in this video, and discover exclusive behind-the-scenes recordings from the film production.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-lg p-lg-5' >
                    <div className="row d-flex justify-content-center p-lg-5">
                        <div className="col-lg-12">
                            <video autoPlay controls loop muted className='FredueStaysVideoSize'   >
                                <source src={require("../Image/EFvideo1.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>


            </section>

            <section>
                <div className="container-fluid">
                    <div className="container-lg">
                        <div className="row d-flex justify-content-center  ">
                            <div className='text-center fontFamilyHeader1 '>
                                <h4 className='NowThen' > NOW & THEN </h4>
                            </div>

                            <div className="col-lg-12 SilderReadMore">
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
                                    <div class="carousel-inner p-lg-5">
                                        <div class="carousel-item active ">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder1.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder2.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder3.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder4.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder5.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder6.png")} alt="" />
                                        </div>
                                        <div class="carousel-item">
                                            <img className='w-100' style={{ height: "auto" }} src={require("../Image/2Sileder7.png")} alt="" />
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                        <span class="carousel-control bg-dark " style={{ height: "60px", padding: "17px", fontSize: "15px" }} aria-hidden="true">
                                            <i class="fa-solid fa-angle-left bg-dark"></i></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                        <span class="carousel-control bg-dark" style={{ height: "60px", padding: "17px", fontSize: "15px" }} aria-hidden="true">
                                            <i class="fa-solid fa-angle-right bg-dark"></i></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-lg-5">
                    <div className="row ms-lg-5  fontFamilyHeader1">
                        <div className=' p-5' style={{ fontSize: "20px", fontWeight: "600", color: "#262626" }} >
                            <p> Author: David Barnwell; Art: Madita O’Sullivan; Video: BMW </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0">
                    <div className='Thei4car d-flex justify-content-center position-relative '>
                        <img className='' src={require("../Image/5089.png")} alt="" />
                        <div className='position-absolute ' style={{ top: "14%", left: "11%" }}>
                            <h2 className=' text-white fontFamilyHeader' style={{ fontSize: "3.125vw", fontWeight: "400" }}>  THE i4 </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row fontFamilyHeader Main-Container-Fluid">
                        <div className='text-center mb-4 pb-3'>
                            <h2 className='' style={{ fontSize: "40px" }} >More articles</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 p-0" style={{ borderRight: "1px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/FreudeStays1video.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", color: "#262626", fontWeight: "600" }}>  Lifestyle  <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Strange but real Christmas traditions – or are they?  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/FreudeStays2video.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}>  JOY is three letters   <br></br><p className='h1FontFamily' style={{ fontSize: "2.20375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  Discover the beauty of simple things that bring us JOY  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-6 p-0" style={{ borderLeft: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/FreudeStays3.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily me-lg-2' style={{ fontSize: "14px", lineHeight: "22px", fontWeight: "600" }}> Christmas with Waltz  <br></br><p className='h1FontFamily' style={{ fontSize: "2.04375vw", lineHeight: "2.56875vw", fontWeight: "600" }}>  A bit of humbug and a bit of joy   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center pt-5">
                            <div className="col-lg-9">
                                <div className='row g-4 d-flex justify-content-center p-3'>
                                    <div className="col-lg-3 col-md-4 ">
                                        <div className='BottomButton'>
                                            <NavLink className='text-decoration-none' to='/digitaljourney'>
                                                <button className=''>
                                                    <span className='Hash'> # </span>  Automotive Life
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-4 ">
                                        <div className='BottomButton'>
                                            <NavLink className='text-decoration-none' to='/electricfuture'>
                                                <button>
                                                    <span className='Hash'> # </span>  Electromobility
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-4 ">
                                        <div className='BottomButton'>
                                            <NavLink className='text-decoration-none' to='/freude'>
                                                <button>
                                                    <span className='Hash'> # </span>  FREUDE forever
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-4 ">
                                        <div className='BottomButton'>
                                            <NavLink className='text-decoration-none' to='/circularworld'>
                                                <button>
                                                    <span className='Hash'> # </span>  Family
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FreudeStays
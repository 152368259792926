import React from 'react'
import { NavLink } from 'react-router-dom'

const CircularWorld = () => {
    return (
        <>
            {/* <section>
                <div className='container-fluid ' style={{ backgroundImage: `url(${require("../Image/CircularWorldBackgroundImg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center',borderBottom:"2px solid white" }}>
                    <div className="container " style={{ height: "86vh" }}>
                        <div className="row text-white d-flex justify-content-center align-itmes-center  text-white " >
                            <div className=" mt-5">
                                <div className='fontFamilyHeader pt-lg-2 mt-lg-2'>
                                    <h4 className='abc' style={{ fontSize: "80px" }}>Circular World</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='container-fluid p-0 d-none d-sm-block' >
                    <div className='position-absolute text-white h1FontFamily1' style={{ top: "22%", left: "6%" }} >
                        <h2 style={{ fontSize: "5.059375vw", fontWeight: "600" }}>Circular World</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '700px' }}  >
                        <source src={require("../Image/Header2Video.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.

                    </video>
                </div >

                <div className='container-fluid p-0 d-sm-none d-block' >
                    <div className='position-absolute text-white h1FontFamily1 ' style={{ top: "43%", left: "8%" }} >
                        <h2 style={{ fontSize: "36px", fontWeight: "600" }}>Circular World</h2>
                    </div>
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill", height: '100%' }}  >
                        <source src={require("../Image/Header2ResponsiveVideo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div >
            </section>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row ms-lg-5">
                            <div className='fontFamilyHeader1 p-4' style={{ fontSize: "33px", }}>
                                <p>Sustainability and circular thinking go hand in hand. Technologies and entrepreneurship help develop solutions that lead us harmoniously into the future. Recycling and conserving resources are an integral part of this journey. All under the motto: RE:DUCE, RE:USE, RE:CYCLE, RE:THINK. Join us on our search for the trends and drivers that are shaping our future while protecting the environment.</p>

                            </div>
                            <div>
                                <p className='ms-lg-3' style={{ color: "#8E8EA7", fontSize: "18px" }}>18 April 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <NavLink to='/afterlifebmwcar'>
                            {/* <a href="/afterlifebmwcar"> */}
                                <div className='h-100 HoverEffectVIDEO position-relative' style={{ borderRight: "1px solid white", borderBottom: "2px solid white" }}>
                                    <video autoPlay loop muted className='w-100' style={{ objectFit: "fill", height: "100%" }}  >
                                        <source src={require("../Image/videoCAR2.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>

                                    <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                        <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>Circle of life</b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The journey of a BMW vehicle at the recycling center</p></h5>
                                    </div>
                                </div>
                                </NavLink>
                            {/* </a> */}
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/NorrebroCar.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px" }}><b>Insider’s guide </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>Discovering Nørrebro with Rune Glifberg and the BMW iX1 </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR13.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px" }}><b>CHASING THE GREENEST CAR </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> An audio documentary about sustainability at BMW </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/LadyFrontCar.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Norway road trip   <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Exploring with care: To the polar circle in the BMW iX </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Girl&Boy&Car.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Imagine …  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Driving tomorrow today  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/CoverOfCar.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Looks meet feel <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Luxurious comfort zone: the magic of fabrics and materials </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR5.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b>Sustainable journey </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Discover Japan with a horticulturist and the BMW iX </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/EarthLady.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Audio column  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>The future of circularity  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/HalfLady.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>A dream team  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Sustainability and its best players </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/YoungerLeader.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Young leaders    <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Acting today, changing tomorrow  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/OldLady&Car.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "16px", lineHeight: "25px" }}>Going green  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Travel with the BMW iX: sustainable enjoyment in South Tyrol   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/CircularVideo1.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Circular economy  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Sustainability and luxury in 2040  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-8 p-0">
                            <div className='h-100 HoverEffectVIDEO position-relative' style={{ borderRight: "1px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/CircularVideo.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b>Sustainability </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>Always one step ahead: how BMW protects our environment </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/YG71PUE.png")} alt="" />
                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "17px", lineHeight: "25px" }}><b>Electrifying landscape </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}>With photographer Steve McCurry in the Scottish highlands  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffect position-relative' style={{ borderLeft: "2px solid white", borderBottom: "2px solid white" }}>
                                <img className='w-100 ' style={{ height: "100%" }} src={require("../Image/StlishCar&boy.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "5%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b>What is progress without vision? </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>The BMW i Vision Circular with Domagoj Dukec </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/HotLady.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Sustainability     <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> BMW’s green future – Interview with Dr. Irene Feige   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/Red&WhiteCar.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "16px", lineHeight: "25px" }}>Sustainability <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  BMW takes environmental responsibility seriously    </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR15.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Sustainability <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  From raw materials to recycling: a battery cell life cycle   </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="col-lg-4 p-0 gy-0 " >
                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR18.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2' style={{ fontSize: "17px", lineHeight: "25px" }}><b> Feature  </b><br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  Electrified Wingsuit Flight: the Making-Of  </p></h5>
                                </div>
                            </div>

                            <div className='h-50 HoverEffectVIDEO position-relative' style={{ borderRight: "2px solid white", borderBottom: "2px solid white" }}>
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR16.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='SolutionDropDownFontFamily2 ' style={{ fontSize: "16px", lineHeight: "25px" }}><b> 10 years of BMW i  </b><br></br><p className='h1FontFamily' style={{ fontSize: "17px" }}> The success story of a vision  </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 p-0">
                            <div className="HoverEffect h-100 position-relative" style={{ borderBottom: "2px solid white", borderLeft: "1px solid white" }}>
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/LadyHand.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "3%", left: "2%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Sustainability  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> Recycled materials in BMW interior design  </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5">
                    <div className="row">
                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderRight: "2px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/BMWTyre.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}> Aerodynamic rims      <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}> The BMW iX3 between design and art   </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white", borderRight: "1px solid white" }}>
                            <div className="HoverEffect h-100 position-relative">
                                <img className='w-100' style={{ height: "100%" }} src={require("../Image/CarNearBySea.png")} alt="" />

                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "3%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "16px", lineHeight: "25px" }}> An act of rethinking  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>   Electricity not coal: the Lausitzer Seenland and the BMW iX3     </p></h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0" style={{ borderBottom: "2px solid white", borderLeft: "2px solid white" }}>
                            <div className="HoverEffectVIDEO h-100 position-relative">
                                <video autoPlay loop muted className='w-100  ' style={{ objectFit: "fill", height: "100%" }}  >
                                    <source src={require("../Image/videoCAR14.mp4")} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute text-white' style={{ bottom: "4%", left: "4%" }}>
                                    <h5 className='h1FontFamily' style={{ fontSize: "17px", lineHeight: "25px" }}>Lifestyle  <br></br><p className='h1FontFamily' style={{ fontSize: "18px" }}>  High-end, high sustainability: Green & gorgeous accessories    </p></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CircularWorld
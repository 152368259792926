import React, { useState } from 'react'
import Header2 from './Header2'
import { NavLink } from 'react-router-dom'
import { Modal, } from "react-bootstrap";


const Models = () => {




    const [show, setShow] = useState(false);
    const [productdetials, setProductdetails] = useState("");
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const data = [{

        ////////////////////////BMWI///////////////////////////////////////

        name: "BMW iX",
        fuel: "Full-Electric",
        price: "12,100,000",
        series: "BMWi",
        img1: require("../Image/ModelsCAR1.png"),
        img2: require("../Image/ModelCARBackImg.png"),
        img3: require("../Image/Onclick1.png")

    },
    {
        name: "BMW i4",
        fuel: "FULL-ELECTIRC",
        price: "7,390,000",
        series: "BMWi",
        img1: require("../Image/ModelsCAR2.png"),
        // img2: require("../Image/ModelCARBackImg.png")
        img3: require("../Image/Onclick2.png")


    },
    {
        name: "BMW i7",
        fuel: "FULL-ELECTIRC",
        price: "19,500,000",
        series: "BMWi",
        img1: require("../Image/ModelsCAR3.png"),
        // img2: require("../Image/ModelCARBackImg.png")
        img3: require("../Image/Onclick3.png")

    },


    ////////////////////////X///////////////////////////////////////

    {
        name: " THE FIRST-EVER X3 M40i ",
        fuel: "PETROL",
        price: "8,650,000",
        series: "X",
        img1: require("../Image/ModelsCARX1.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick4.png")

    },

    {
        name: " THE NEW BMW X7 ",
        fuel: "PETROL . DIESEL",
        price: "`12,200,000",
        series: "X",
        img1: require("../Image/ModelsCARX2.png"),
        img3: require("../Image/Onclick5.png")

    },

    {
        name: " BMW X5",
        fuel: "PETROL . DIESEL",
        price: "5,650,000",
        series: "X",
        img1: require("../Image/ModelsCARX3.png"),
        img3: require("../Image/Onclick6.png")

    },

    {
        name: " THE BMW X3 ",
        fuel: "DIESEL",
        price: "6,750,000",
        series: "X",
        img1: require("../Image/ModelsCARX4.png"),
        img3: require("../Image/Onclick7.png")

    },

    {
        name: " THE NEW BMW X1",
        fuel: "PETROL . DIESEL",
        price: "4,590,000",
        series: "X",
        img1: require("../Image/ModelsCARX5.png"),
        img3: require("../Image/Onclick8.png")

    },


    ////////////////////////M///////////////////////////////////////

    {
        name: " THE NEW BMW Z4 M40i",
        fuel: "PETROL ",
        price: " 9,650,000",
        series: "M",
        img1: require("../Image/M1.png"),
        img3: require("../Image/Onclick9.png")

    },

    {
        name: " THE FIRST-EVER X3 M40i",
        fuel: "PETROL ",
        price: "8,650,000",
        series: "M",
        img1: require("../Image/M2.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick10.png")

    },

    {
        name: " THE FIRST-EVER BMW XM",
        fuel: "PLUH-INHYBRID",
        price: "26,000,000",
        series: "M",
        img1: require("../Image/M3.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick11.png")

    },

    {
        name: " THE new BMW M340i",
        fuel: "PETROL ",
        price: "6,920,000",
        series: "M",
        img1: require("../Image/M4.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick12.png")

    },

    {
        name: " BMW M8 Competition coupe",
        fuel: "PETROL ",
        price: "24,400,000",
        series: "M",
        img1: require("../Image/M5.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick13.png")

    },

    {
        name: " BMW M8 Competition coupe with M xDrive",
        fuel: "PETROL ",
        price: "14,750,000",
        series: "M",
        img1: require("../Image/M6.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick14.png")

    },


    ////////////////////////7///////////////////////////////////////

    {
        name: "THE ALL-NEW BMW 7 SERIES SEDAN",
        fuel: "PETROL ",
        price: "17,000,000",
        series: "7",
        img1: require("../Image/7.png"),
        img3: require("../Image/Onclick15.png")

    },


    ////////////////////////6///////////////////////////////////////

    {
        name: "BMW 6 series Gran Turismo",
        fuel: "PETROL . DIESEL",
        price: "7,190,000",
        series: "6",
        img1: require("../Image/6.png"),
        img3: require("../Image/Onclick16.png")

    },


    ////////////////////////5///////////////////////////////////////


    {
        name: "BMW 5 Series Sedan",
        fuel: "DIESEL",
        price: "6,890,000",
        series: "5",
        img1: require("../Image/5.png"),
        img3: require("../Image/Onclick17.png")

    },


    ////////////////////////3///////////////////////////////////////

    {
        name: "The new BMW M340i",
        fuel: "PETROL",
        price: "6,920,000",
        series: "3",
        img1: require("../Image/M4.png"),
        img2: require("../Image/x1.png"),
        img3: require("../Image/Onclick18.png")

    },

    {
        name: "The new BMW 3 Series Gran Limousine",
        fuel: "PETROL . DIESEL",
        price: "5,890,000",
        series: "3",
        img1: require("../Image/3_2.png"),
        img3: require("../Image/Onclick19.png")

    },

    ////////////////////////2///////////////////////////////////////

    {
        name: " BMW 2 Series Gran Coupe",
        fuel: "PETROL . DIESEL",
        price: "4,350,000",
        series: "2",
        img1: require("../Image/2_1.png"),
        img3: require("../Image/Onclick20.png")

    },

    ////////////////////////Z4///////////////////////////////////////

    {
        name: "THE NEW BMW Z4 M40i",
        fuel: "PETROL",
        price: "3,350,000",
        series: "Z4",
        img1: require("../Image/M1.png"),
        img3: require("../Image/Onclick21.png")

    },
    ]

    const uniqueData = (product, property) => {
        let newVal = product.map((cele) => {

            return (cele[property])
        });

        return newVal = [...new Set(newVal)];

    }

    const seriesData = uniqueData(data, "series")

    return (
        <>





            {/* <section>
                <div className='container-fluid ' >
                    <div>
                        <Header2 />
                    </div>
                    <div className="container " >
                        <div className="row pt-5 d-flex justify-content-center align-itmes-center " >

                            <div className="col-lg-11">
                                <div className='h1FontFamily1 pt-lg-2 mt-lg-2'>
                                    <h4 className=' ' style={{ fontSize: "40px" }}>FIND YOUR BMW.</h4>
                                    <h5 className=' pt-3' style={{ fontSize: "25px" }}>FILTER</h5>
                                    <div className='pt-3' >
                                        <h6><i class="fa-solid fa-angle-right fs-5"></i>&nbsp;&nbsp; <b className='fs-5'>Show more filters</b></h6>
                                    </div>
                                    <div className='pt-3'>
                                        <h5 className='fontFamilyHeader1'><b>21</b> vehicles found</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </section> */}

            <section>
                <div className='container-fluid p-0' >
                    <div className='bg-dark pb-5 d-none d-sm-block d-block'>
                        <Header2 />
                    </div>

                    <div className="container " >
                        <div className="row pt-5 d-flex justify-content-center align-itmes-center " >

                            <div className="col-lg-11">
                                <div className='h1FontFamily1 pt-lg-2 mt-lg-2'>
                                    <h4 className=' ' style={{ fontSize: "40px" }}>FIND YOUR BMW.</h4>
                                    <h5 className=' pt-3' style={{ fontSize: "25px" }}>FILTER</h5>
                                    <div className='pt-3' >
                                        <h6><i class="fa-solid fa-angle-right fs-5"></i>&nbsp;&nbsp; <b className='fs-5'>Show more filters</b></h6>
                                    </div>
                                    <div className='pt-3'>
                                        <h5 className='fontFamilyHeader1'><b>21</b> vehicles found</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section>

            <section>
                <div className="container-fluid SECONDHEADERLINK1 d-none d-block ">
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-11">
                                <nav className="navbar navbar-expand-lg p-0 text-dark text-dark   ">
                                    <div className="container ">
                                        <div className="row pt-3 d-flex w-100 " style={{ paddingBottom: "9px" }} >
                                            <div className=" pb-3 ">

                                                <ul className="navbar-nav ms-lg-4 ps-lg-4  d-flex  "  >

                                                    <div className='nav-item mt-lg-2  mt-lg-2 '>
                                                        <NavLink className="text-dark  text-decoration-none   " style={{ fontSize: "18px", }}  > <b className='BMWi p-4'>BMWi</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none " style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>X</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>M</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2 mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>7</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2 mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>6</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>5</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>3</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>2</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>Z4</b> </NavLink>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </nav >
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid SECONDHEADERLINK1 d-none d-sm-block ">
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-11">
                                <nav className="navbar navbar-expand-lg p-0 text-dark text-dark   ">
                                    <div className="container ">
                                        <div className="row pt-3 d-flex w-100 " style={{ paddingBottom: "9px" }} >
                                            <div className=" pb-3 ">

                                                <ul className="navbar-nav ms-lg-4 ps-lg-4  d-flex  "  >

                                                    <div className='nav-item mt-lg-2  mt-lg-2 '>
                                                        <NavLink className="text-dark  text-decoration-none   " style={{ fontSize: "18px", }}  > <b className='BMWi p-4'>BMWi</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none " style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>X</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>M</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2 mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>7</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2 mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>6</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>5</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>3</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>2</b> </NavLink>
                                                    </div>
                                                    <div className='nav-item mt-lg-2  mt-lg-2'>
                                                        <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1" style={{ fontSize: "18px" }}  > <b className='MINIHEADER p-4'>Z4</b> </NavLink>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </nav >
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                {
                    seriesData.length === 0 || seriesData === "undefined" || seriesData === null ?
                        <>
                            <div className='w-100 h-100 fs-3 text-center'> NO PRODUCT AVIALBLE! </div>
                            <p className='text-center'>SORRY FOR INCONIVNECE</p>
                        </> : seriesData.map((row) => {
                            return (
                                <>
                                    <div className="container-fluid">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-11 ms-lg-5 ps-lg-3">
                                                    <div className="col-12">
                                                        <div className="row p-2  ">
                                                            <h6 className='fs-5 p-0 mt-3 ' style={{ color: "#555555" }}>{row}</h6>
                                                            {
                                                                data.length === 0 || data === "undefined" || data === null ?
                                                                    <>
                                                                        <div className='w-100 h-100 fs-3 text-center'> NO {row} PRODUCT AVIALBLE! </div>
                                                                        <p className='text-center'>SORRY FOR INCONIVNECE</p>
                                                                    </> :
                                                                    <>
                                                                        {
                                                                            data.map((ele) => {

                                                                                if (ele.series === row) {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="col-lg-3 col-md-6  p-0">
                                                                                                <button className='text-start ' style={{ border: "none", backgroundColor: "white" }} onClick={() => {
                                                                                                    handleShow();
                                                                                                    setProductdetails(ele);
                                                                                                }}>
                                                                                                    <h6 className='text-end '><button className=' text-white fw-bold' style={{ fontSize: "13px", border: 'none', backgroundColor: "black" }}>NEW</button></h6>
                                                                                                    <div className='SECONDBMWIX'>
                                                                                                        <img className='w-100 ' style={{ height: "200px", marginBottom: "-30px" }} src={ele.img1} alt="" />
                                                                                                    </div>
                                                                                                    {
                                                                                                        ele.img2 ? <> <img className='' style={{ width: "15%", }} src={ele.img2} alt="" /> </> : <> <div className='p-2 mt-2'></div> </>
                                                                                                    }
                                                                                                    <h5 className='mt-2' style={{ fontSize: "18px" }} >{ele.name}</h5>
                                                                                                    <h5 className='' style={{ fontSize: "14px" }} >{ele.fuel}</h5>
                                                                                                    <h5 className='' style={{ fontSize: "15px" }} >Starting from ₹ {ele.price} <i class="fs-5 fa-sharp fa-solid fa-circle-info"></i></h5>
                                                                                                </button>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div >

                                    {row === productdetials.series &&
                                        <>
                                            <section>
                                                {
                                                    productdetials.length === 0 || productdetials === "undefined" || productdetials === null ?
                                                        < >
                                                            <Modal show={show} onHide={handleClose}>
                                                                <Modal.Header >
                                                                    <div className='w-100 h-100 p-5 text-center'> "somethings went wrong" </div>
                                                                </Modal.Header>
                                                            </Modal>
                                                        </> :


                                                        <div show={show} onHide={handleClose} >
                                                            <div >
                                                                <section>
                                                                    <div className="container-fluid">
                                                                        <div className="container">
                                                                            <div className="row " style={{ width: "100%", height: "100vh" }}>
                                                                                <button className='text-start ' style={{ border: "none", backgroundColor: "white", }} >
                                                                                    <h6 className='text-end '><button className=' text-white fw-bold' style={{ fontSize: "13px", border: 'none', backgroundColor: "white" }}>NEW</button></h6>
                                                                                    <div className='d-flex justify-content-center align-items-center position-relative'>
                                                                                        <img className=' w-50' src={productdetials.img3} alt="" />

                                                                                        <div className='row d-flex justify-content-lg-center align-items-lg-center pt-5 position-absolute ' style={{ top: "58%", width: '100%' }} >
                                                                                            <div className='col-lg-3 col-md-6 '>
                                                                                                <div className='h-100 p-2' style={{ color: "#525252", }}>
                                                                                                    {
                                                                                                        productdetials.img2 ? <> <img className='' style={{ width: "15%", }} src={productdetials.img2} alt="" /> </> : <> <div className=''></div> </>
                                                                                                    }

                                                                                                    <h6 className='mt-2' style={{ fontSize: "18px", }} >{productdetials.name}</h6>
                                                                                                    <h6 className='' style={{ fontSize: "14px", }} >{productdetials.fuel}</h6>
                                                                                                    <h6 className='' style={{ fontSize: "18px", }} >Starting from ₹ {productdetials.price} <i class="fs-5 fa-sharp fa-solid fa-circle-info"></i></h6>
                                                                                                    <div className='mt-lg-3 '>
                                                                                                        <button className='w-100 p-2'><b className='p-lg-5' style={{ fontSize: "18px", color: "#525252" }}>Show More</b></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-md-6'>
                                                                                                <div className='h-100 pt-2 mt-2 p-2'>
                                                                                                    <div className='text-center '>
                                                                                                        <h6 className='mt- text-white' style={{ fontSize: "18px", }} >car</h6>
                                                                                                        <i class="fa-solid fa-car fs-1 mt-4"></i>
                                                                                                        <button className='w-100 mt-3 p-2 bg-primary text-light'><b className='p-lg-5' style={{ fontSize: "18px", }}>Configure & Price</b></button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                            <div className='col-lg-3 col-md-6'>
                                                                                                <div className='h-100 pt-lg-5 mt-lg-5 p-2'>

                                                                                                    <NavLink ><button className='w-100 p-2 mt-4'><b className='p-lg-5 ' style={{ fontSize: "18px", color: "#525252" }}>Register</b></button></NavLink>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </section>



                                                            </div>
                                                        </div>



                                                }
                                                <div className='BWIBORDERBOTTOMLINK mt-4'>
                                                </div>
                                            </section>

                                        </>



                                    }

                                </>
                            )
                        })
                }



            </section >




        </>
    )
}

export default Models
import React from 'react'
import { Routes, Route } from 'react-router'
import Home from './Home'
import CircularWorld from './CircularWorld'
import DigitalJourney from './DigitalJourney'
import ElectricFuture from './ElectricFuture'
import Freude from './Freude'
import Header2 from './Header2'
import Models from './Models'
import Header from './Header'
import Footer from './Footer'
import FreudeStays from './FreudeStays'
import AfterlifeBmwCar from './AfterlifeBmwCar'
import Error from './Error'

const Routing = () => {



    return (
        <>
            <React.Fragment>
                <header className='position-sticky ' style={{ zIndex: 1 }}>
                    <Header/>
                </header>
                <main className='mainMargin'>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/circularworld" element={<CircularWorld />} />
                        <Route to path="/digitaljourney" element={<DigitalJourney />} />
                        <Route to path="/electricfuture" element={<ElectricFuture />} />
                        <Route to path="/freude" element={<Freude />} />
                        <Route to path="/header2" element={<Header2 />} />
                        <Route to path="/models" element={<Models />} />
                        <Route to path="/freudestays" element={<FreudeStays />} />
                        <Route to path="/afterlifebmwcar" element={<AfterlifeBmwCar />} />

                        <Route to path="*" element={<Error />} />

                    </Routes>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing
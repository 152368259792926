import React from 'react'
import { NavLink } from "react-router-dom";

const Header2 = () => {

    return (

        <nav className="navbar navbar-expand-lg p-0 text-dark   ">
            <div className="container ">
                <div className="row pt-2 d-flex w-100 "  >

                    <div className="col-lg-12 pb-3 ">
                        <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa-solid fa-bars text-dark"></i>
                        </button>
                        <div className="collapse navbar-collapse mt-lg-0  mt-3 " id="navbarNavDropdown">
                            <ul className="navbar-nav ms-lg-3 ps-lg-3  d-flex justify-content-lg-around align-items-lg-center w-100"  >

                                <li className='nav-item mt-lg-2 p-2 mt-lg-2 '>
                                    <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size" to="/models" > <b className=''>Models</b> </NavLink>
                                </li>
                                <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                    <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Electric</b> </NavLink>
                                </li>
                                <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                    <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Configurator</b> </NavLink>
                                </li>
                                <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                    <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size"> <b className=''>Visit Online Shop</b> </NavLink>
                                </li>
                                <li className='nav-item mt-lg-2 p-2 mt-lg-2'>
                                    <NavLink className="text-dark  text-decoration-none   fontFamilyHeader1 header-two-common-navbarr-size" to='/'> <b className=''>More BMW</b> </NavLink>
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>
            </div>
        </nav>

    )
}

export default Header2